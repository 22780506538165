import { motion } from "framer-motion";
import React from "react";

import "./Documents.scss";

const Documents = () => {

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/kezikonyv_politika.pdf'; // Path to the PDF in the public folder
    link.download = 'kezikonyv_politika.pdf'; // Name for the downloaded file
    link.click();
};


  return (
    <div className="app__contact" id="documents">
      <motion.div 
        whileInView={{ y: [0, 0], opacity: [0, 1] }}
        transition={{ duration: 0.6 }}
        className="app__contact-flex-left"
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <p className="app__contact-flex-left-title">Dokumentumok letöltése</p>
        <br></br>
        <motion.div
          whileInView={{ y: [-140, 0], opacity: [0, 1] }}
          transition={{ duration: 0.6 }}
        >
          <button onClick={handleDownload} className="download-button">
            Kézikönyv MIR-KIR politika
        </button>
        </motion.div>
        
      </motion.div>
    </div>
  );
};

export default Documents;
