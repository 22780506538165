import React, { useState } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";

import { images } from "../../constants";
import "./Navbar.scss";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const navbarItems = [
    "tender/Pályázat",
    "about/Cégismertető",
    "activity/Tevékenységi kör",
    //"news/Hírek",
    //"gallery/Képgaléria",
    "documents/Dokumentumok",
    "contact/Kapcsolat"
  ];

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <a href={`#header`} onClick={() => setToggle(false)}>
          <img src={images.logo} alt="HGH" />
        </a>
      </div>
      <ul className="app__navbar-links">
        {navbarItems.map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <a href={`#${item.split("/")[0]}`} onClick={() => setToggle(false)}>
              {item.split("/")[1]}
            </a>
          </li>
        ))}
      </ul>
      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {toggle && (
          <motion.div
            whileInView={{ x: [200, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul className="app__navbar-links">
              {navbarItems.map((item) => (
                <li key={item}>
                  <a
                    href={`#${item.split("/")[0]}`}
                    onClick={() => setToggle(false)}
                  >
                    {item.split("/")[1]}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
