import React from "react";

import { Navbar } from "./components";
import {
  About,
  CompanyActivity,
  Gallery,
  News,
  Reference,
  Documents,
  Footer,
  Contact,
  TenderDetails,
} from "./container";
import "./App.scss";

const App = () => {
  return (
    <div className="app">
      <Navbar />
      <TenderDetails />
      <About />
      <CompanyActivity />
      <Documents />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
